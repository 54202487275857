export const separateEvents = events => {
  const currentDate = new Date().toISOString()
  let pastEvents = []
  let futureEvents = []

  events?.forEach(item => {
    if (item?.node?.data?.start_date > currentDate) {
      futureEvents.push(item)
    } else {
      pastEvents.push(item)
    }
  })

  return [...futureEvents.reverse(), ...pastEvents]
}
