import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import moment from "moment"

const BlogItem = ({ blog, basePath, location }) => {
  const {
    uid,
    data: { title, category, featured_image: image, author },
    first_publication_date,
  } = blog
  let blogURL = category.uid ? `${basePath}/${uid}` : `${basePath}/${uid}`
  const {
    data: { name: categoryName },
  } = category.document
  const blogimage = getImage(image)
  const createdDate = moment(first_publication_date).format("ll")

  const url = `${blogURL}${
    location?.pathname?.includes("blog") ? `?from=${location.pathname}` : ""
  }`
  return (
    <Item>
      <Link to={url}>
        <Image>
          <GatsbyImage image={blogimage} alt={title.text ?? ""} />
        </Image>
      </Link>
      <Wrapper>
        <Category>
          {" "}
          <Link to={`/category/${category?.uid}`}>{categoryName}</Link>
        </Category>
      </Wrapper>
      <Link to={url}>
        <TextWrap>
          <h4>{title.text}</h4>
          <PersonWrap>
            <ImageWrapper>
              <GatsbyImage
                image={getImage(author?.document?.data?.photo?.gatsbyImageData)}
                alt={title.text ?? ""}
              />
            </ImageWrapper>
            <PersonText>
              <h5>{author?.document?.data?.name}</h5>
              <p>{createdDate}</p>
            </PersonText>
          </PersonWrap>
        </TextWrap>
      </Link>
    </Item>
  )
}

BlogItem.propTypes = {
  blog: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
}

export default BlogItem

const Item = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 12px;
  background-color: #fff;
  box-shadow: ${({ theme }) => theme.box.boxShadow};
  &:hover {
    img {
      transform: scale(1.04);
    }
  }

  img {
    display: block;
    transition: 0.7s ease-in-out !important;
  }

  h4 {
    font-size: 24px;
    line-height: calc(32 / 24);
    position: relative;
    margin: 0 0 12px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-right: 20px;
  }

  a {
    &:hover {
      h4 {
        &:after {
          transform: rotate(5deg) translateX(-2px);
          opacity: 0.6;
        }
      }
    }
  }

  & > a {
    &:nth-last-of-type(1) {
      flex-grow: 1;
    }
  }
`
const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Category = styled.div`
  margin: 16px 0;

  a {
    padding: 8px 0px;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    background-color: transparent;
    color: #2a95bf;
    text-decoration: underline;
  }
`
const Image = styled.div`
  display: flex;
  height: 240px;

  .gatsby-image-wrapper {
    width: 100%;
  }
`
const PersonWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
`

const ImageWrapper = styled.div`
  display: flex;
  flex: 0 0 40px;
  height: 40px;
  align-items: stretch;
  border-radius: 50%;
  overflow: hidden;
`

const PersonText = styled.div`
  padding-left: 12px;

  h5 {
    color: #101828;
    font-size: 14px;
    margin-bottom: 0;
    line-height: calc(20 / 14);
  }

  p {
    margin-bottom: 0;
    font-size: 14px;
    line-height: calc(20 / 14);
    color: #667085;
  }
`
