import videoInRetail from './vide-in-retail.jpg'
import foodWasteAndMarkdowns from './food-waste.jpg'
import eCommerceLoss from './e-commerce-loss.jpg'
import inventoryAccuracy from './inventory-accuracy.jpg'
import onShelfAvailability from './on-shelf-availability.jpg'
import selfCheckout from './self-checkout.jpg'
import rfidInRetail from './rfid.jpg'
import totalRetailLoss from './total-retail-loss.jpg'

const categoryCovers = {
	"video-in-retail": { background: videoInRetail },
	"food-waste-and-markdowns": {background: foodWasteAndMarkdowns },
	"ecommerce-loss": { background: eCommerceLoss },
	"inventory-accuracy": {background: inventoryAccuracy },
	"on-shelf-availability": {background: onShelfAvailability},
	"self-checkout": {background: selfCheckout},
	rfid: {background: rfidInRetail},
	"retail-loss": {background: totalRetailLoss},
}

export { categoryCovers }