import React from "react"

import { Section } from "./ResearchList"

import styled from "styled-components"
import AllAccordions from "../FAQ/AllAccordions"

const QuestionsList = ({data, categoryName}) => {
	return (
		<Section>
			<HeadTitle>FAQs {categoryName}</HeadTitle>
			<AllAccordions data={data} background={"rgba(42, 149, 191, 0.8)"}/>
		</Section>
	)
}

export default QuestionsList

const HeadTitle = styled.h3`
  font-size: 24px;
	margin-top: 12px;
  line-height: 36px;
  color: white;
  margin-bottom: 10px;

  @media (min-width: ${({theme}) => theme.media.tablet_min}) {
    font-size: 36px;
    line-height: 56px;
  }
`