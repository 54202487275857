import { Icon } from "../Icon"
import React, { useRef, useState } from "react"
import styled from "styled-components"

const AllAccordions = ({data, background}) => {
	const [activeIndex, setActiveIndex] = useState(null);
	const accordionsRef = useRef(null)
	const handleClick = (index) => {
		setActiveIndex(index === activeIndex ? null : index);
	};

	return (
		<AllAccordionsWrapper id="accordions-container" ref={accordionsRef} background={background}>
			{data?.map((el, i) => {
				return (
					<AccordionWrapper key={i} active={activeIndex === i} background={background}>
						<Title active={activeIndex === i} onClick={() => handleClick(i)}>
							<div dangerouslySetInnerHTML={{ __html: el?.node?.data?.question.html }} />
							<Icon type='TRIANGLE_ARROW'/>
						</Title>
						<TextWrapper active={activeIndex === i} dangerouslySetInnerHTML={{ __html: el?.node?.data?.answer.html }} />
					</AccordionWrapper>
				)
			})}
		</AllAccordionsWrapper>
	)
}

export default AllAccordions

const AllAccordionsWrapper = styled.div`
  position: relative;
  max-height: 1020px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`

const AccordionWrapper = styled.div`
  background-color: ${props => props.background};
  margin-bottom: 15px;
  box-shadow: ${({ theme }) => theme.box.boxShadow};
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`

const Title = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  position: relative;
  width: 100%;
  padding: 20px;

  h1 {
    color: white;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    margin: 0;
    font-weight: 700;
    text-align: left;
    padding-right: 10px;

    @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
      font-size: 20px;
      line-height: 24px;
      padding-right: 40px;
    }
  }

  svg {
    position: absolute;
    transform: rotate(${props => (props.active ? "180deg" : "0")});
    right: 10px;
    top: 25px;

    @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
      right: 30px;
      top: 25px;
    }
  }
`

const TextWrapper = styled.div`
  overflow-y: auto;
  max-height: ${props => (props.active ? "300px" : "0")};
  transition: ${({ theme }) => theme.durations.transition};
  padding: 0 20px 0;
  margin-top: 0;

  ul {
    margin-bottom: 32px;
    li {
      color: #e9e9e9;
      font-size: 16px;
      line-height: 30px;
      font-weight: 400;
      list-style-type: circle;
      padding-left: 15px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 5px;
        height: 5px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: white;
        border-radius: 50%;
      }
    }
  }

  p {
    color: #e9e9e9;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 15px;

    @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
      font-size: 20px;
      line-height: 30px;
    }
  }
`