import React, {useRef, useState} from 'react';
import {ButtonNext, ButtonPrev, Section, SliderWrapper, StyledSwiper, Title} from "./ResearchList";
import {SwiperSlide} from "swiper/react";
import styled from "styled-components";
import PodcastSliderItem from "../Podcast/PodcastSliderItem";

const PodcastList = ({data, categoryName, location}) => {
  const [isFirstSlide, setIsFirstSlide] = useState()
  const [isLastSlide, setIsLastSlide] = useState()
  const swiperRef = useRef(null);
  const handlePrevButtonClick = () => {
    swiperRef.current.slidePrev();
  };
  const locationPath = location.pathname.split("/");
  const category = locationPath[locationPath.length - 1];
  const itemData = data.reduce((total, amount) => {
    if (amount.node.data.episodes.length > 0) {
      amount.node.data.episodes.forEach(item => {
        const itemPath = item.episode_link.url.split("/");
        const itemCategory = itemPath[itemPath.length - 1];
        if (itemCategory === category) {
          total.push({
            image: amount.node.data.series_image,
            ...item,
            link: amount.node.uid
          })
        }
      })
    }
    return total
  }, []);
  const handleNextButtonClick = () => {
    swiperRef.current.slideNext();
  };
  if (itemData.length === 0) {
    return null
  }
  return (
    <Section>
      <BlogsTitle>Podcast {categoryName}</BlogsTitle>
      <SliderWrapperBlogs isFirstSlide={isFirstSlide} isLastSlide={isLastSlide}>
        <StyledSwiper
          onSwiper={swiper => {
            swiperRef.current = swiper
            setIsFirstSlide(swiper.isBeginning)
            setIsLastSlide(swiper.isEnd)
          }}
          onResize={swiper => {
            setIsFirstSlide(swiper.isBeginning)
            setIsLastSlide(swiper.isEnd)
          }}
          onActiveIndexChange={(swiper) => {
            setIsFirstSlide(swiper.isBeginning)
            setIsLastSlide(swiper.isEnd)
          }}
          observer={true}
          observeParents={true}
          spaceBetween={15}
          slidesPerView={1}
          enabled={true}
          slidesPerGroup={1}
          breakpoints={{
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
              enabled: data.length > 2,
              slidesPerGroup: 2,
            },
            1180: {
              slidesPerView: 3,
              enabled: data.length > 3,
              slidesPerGroup: 3,
            },
          }}
        >
          {
            itemData?.map((el, i) => {
              return (
                <SwiperSlide key={i}>
                  <PodcastSliderItem
                    basePath={'/podcast'}
                    podcast={el}
                  />
                </SwiperSlide>
              )
            })
          }
        </StyledSwiper>
        <ButtonPrev onClick={handlePrevButtonClick}></ButtonPrev>
        <ButtonNext onClick={handleNextButtonClick}></ButtonNext>
      </SliderWrapperBlogs>
    </Section>
  );
};

export default PodcastList;

const BlogsTitle = styled(Title)`
	margin-bottom: 25px;
`

const SliderWrapperBlogs = styled(SliderWrapper)`
	width: 96%;
`