import React, { useRef, useState } from "react"

import { ButtonNext, ButtonPrev, Section, SliderWrapper, StyledSwiper, Title } from "./ResearchList"
import styled from "styled-components"

import { SwiperSlide } from "swiper/react"
import BlogItem from "../Blog/BlogItem"


const BlogsList = ({data, categoryName}) => {
	const [isFirstSlide, setIsFirstSlide] = useState()
	const [isLastSlide, setIsLastSlide] = useState()
	const swiperRef = useRef(null);

	const handlePrevButtonClick = () => {
		swiperRef.current.slidePrev();
	};

	const handleNextButtonClick = () => {
		swiperRef.current.slideNext();
	};

	return (
		<Section>
			<BlogsTitle>Blog {categoryName}</BlogsTitle>
			<SliderWrapperBlogs isFirstSlide={isFirstSlide} isLastSlide={isLastSlide}>
				<StyledSwiper
					onSwiper={swiper => {
						swiperRef.current = swiper
						setIsFirstSlide(swiper.isBeginning)
						setIsLastSlide(swiper.isEnd)
					}}
					onResize={swiper => {
						setIsFirstSlide(swiper.isBeginning)
						setIsLastSlide(swiper.isEnd)
					}}
					onActiveIndexChange={(swiper) => {
						setIsFirstSlide(swiper.isBeginning)
						setIsLastSlide(swiper.isEnd)
					}}
					observer={true}
					observeParents={true}
					spaceBetween={15}
					slidesPerView={1}
					enabled={true}
					slidesPerGroup={1}
					breakpoints={{
						768: {
							slidesPerView: 2,
							spaceBetween: 20,
							enabled: data.length > 2,
							slidesPerGroup: 2,
						},
						1180: {
							slidesPerView: 3,
							enabled: data.length > 3,
							slidesPerGroup: 3,
						},
					}}
				>
					{
						data?.map((el, i) => {
							return (
								<SwiperSlide key={i}>
									<BlogItem
										basePath={'/blog'}
										blog={el.node}
									/>
								</SwiperSlide>
							)
						})
					}
				</StyledSwiper>
				<ButtonPrev onClick={handlePrevButtonClick}></ButtonPrev>
				<ButtonNext onClick={handleNextButtonClick}></ButtonNext>
			</SliderWrapperBlogs>
		</Section>
	)
}

export default BlogsList

const BlogsTitle = styled(Title)`
	margin-bottom: 25px;
`

const SliderWrapperBlogs = styled(SliderWrapper)`
	width: 96%;
`
