import React from 'react';
import {GatsbyImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import styled from "styled-components";

const PodcastSliderItem = ({podcast, basePath}) => {
  const title = podcast.episode_title;
  const content = podcast.episode_text;
  const series_image = podcast.image;
  const podcastURL = `${basePath}#${title.text}`;
  const contentText = `${content.text.split(' ').slice(0, 20).join(' ')}...`
  return (
    <PodcastWrapper>
      <Link to={podcastURL}>
        <Image>
          <GatsbyImage image={series_image?.gatsbyImageData} alt={"preview"} />
        </Image>
        <div>
          <h2>{title.text}</h2>
          <PodcastText>{contentText}</PodcastText>
        </div>
      </Link>
    </PodcastWrapper>
  );
};

export default PodcastSliderItem;

const PodcastWrapper = styled.div`
  background: rgb(14,79,117);
  padding: 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.19) 0 5px 5px, rgba(0, 0, 0, 0.23) 0 6px 8px;
  margin-bottom: 30px;
  & * {
    color: white;
  }
  h2 {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 1.5;
  }
  p {
    font-size: 16px;
  }
  @media (max-width: 480px) {
    padding: 15px;
  }
`
const PodcastText = styled.p`
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 1.7;
  @media (max-width: 640px) {
    line-height: 1.4;
    font-size: 16px;
  }
  @media (max-width: 480px) {
    line-height: 1.4;
    font-size: 14px;
  }
`
const Image = styled.div`
  display: flex;
  height: 240px;
  margin-bottom: 20px;
  width: 100%;
  margin-right: 20px;
  &:hover {
    img {
      transform: scale(1.04);
    }
  }
  img {
    cursor: pointer;
    display: block;
    transition: 0.7s ease-in-out !important;
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`
