import React, { useRef, useState } from "react"
import { truncateString } from "../../utils"
import { Link } from "gatsby"
import styled from "styled-components"

import { categoryCovers } from "../../assets/categoryCovers/categoryCovers"

import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css/navigation"
import CardLogo from "../CardLogo"

const ResearchList = ({ data }) => {
  const [isFirstSlide, setIsFirstSlide] = useState()
  const [isLastSlide, setIsLastSlide] = useState()
  const swiperRef = useRef(null)

  const handlePrevButtonClick = () => {
    swiperRef.current.slidePrev()
  }

  const handleNextButtonClick = () => {
    swiperRef.current.slideNext()
  }

  return (
    <Section>
      <TextWrap>
        <Title>Research papers</Title>
        <p>
          Our research papers offer groundbreaking insights and
          actionable outcomes to help retailers and their partners
          better manage the many ways in which profits can be negatively
          impacted by all forms of retail loss. Produced by some of the
          leading academic experts in the field of retailing and loss
          prevention, they are all free to download.
        </p>
      </TextWrap>

      <SliderWrapper isFirstSlide={isFirstSlide} isLastSlide={isLastSlide}>
        <StyledSwiper
          onSwiper={swiper => {
            swiperRef.current = swiper
            setIsFirstSlide(swiper.isBeginning)
            setIsLastSlide(swiper.isEnd)
          }}
          onResize={swiper => {
            setIsFirstSlide(swiper.isBeginning)
            setIsLastSlide(swiper.isEnd)
          }}
          onActiveIndexChange={swiper => {
            setIsFirstSlide(swiper.isBeginning)
            setIsLastSlide(swiper.isEnd)
          }}
          resizeObserver={true}
          observer={true}
          observeParents={true}
          spaceBetween={15}
          slidesPerView={1}
          enabled={true}
          slidesPerGroup={1}
          breakpoints={{
            768: {
              slidesPerView: 2,
              enabled: data.length > 2,
              slidesPerGroup: 2,
            },
            1180: {
              slidesPerView: 3,
              enabled: data.length > 3,
              slidesPerGroup: 3,
            },
          }}
        >
          {data?.map((el, i) => {
            const {
              data: { authors, category, title, subtitle },
              uid,
            } = el.node
            const researchURL = `/research/${uid}`
            return (
              <SwiperSlide key={i}>
                <Box>
                  <CardLogo
                    name={category?.document?.data.name}
                    color={category?.document?.data.color}
                  />
                  <picture>
                    <img
                      src={categoryCovers[category?.document?.uid]?.background}
                      alt=""
                    />
                  </picture>
                  <ContentWrapper color={category?.document?.data?.color}>
                    <HeaderText>
                      <CardTitle>{title.text}</CardTitle>
                    </HeaderText>
                    <FooterText>
                      <Description>
                        {truncateString(subtitle ? subtitle : "", 120)}
                      </Description>
                      <Author>
                        {authors
                          .map(({ author }) => author?.document?.data?.name)
                          .join(", ")}
                      </Author>
                      <Button to={researchURL}>Learn more</Button>
                    </FooterText>
                  </ContentWrapper>
                </Box>
              </SwiperSlide>
            )
          })}
        </StyledSwiper>
        <ButtonPrev onClick={handlePrevButtonClick}></ButtonPrev>
        <ButtonNext onClick={handleNextButtonClick}></ButtonNext>
      </SliderWrapper>
    </Section>
  )
}

export default ResearchList

export const Section = styled.section`
  padding-bottom: 20px;
`
export const ButtonPrev = styled.button``
export const ButtonNext = styled.button``

export const SliderWrapper = styled.div`
  position: relative;
  height: auto;
  max-width: 360px;
  margin: 0 auto;
  width: 96%;

  @media (min-width: 768px) {
    max-width: 730px;
  }
  @media (min-width: 1180px) {
    max-width: 100%;
  }

  ${ButtonNext},
  ${ButtonPrev} {
    cursor: pointer;
    position: absolute;
    z-index: 10;
    top: 40%;
    width: 40px;
    height: 40px;
    background: ${({ theme }) => theme.colors.primary_color};
    border-radius: 50%;
    transition: ${({ theme }) => theme.durations.transition};
    box-shadow: ${({ theme }) => theme.box.boxShadow};

    &::before {
      position: absolute;
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.9834 1.99996L11.9917 11.9917L1.99993 1.99997' stroke='white' stroke-width='3.07438'/%3E%3C/svg%3E%0A");
      background-size: contain;
      background-repeat: no-repeat;
      border: none;
      width: 20px;
      height: 15px;
      top: 12px;
    }

    &:hover,
    &:active {
      transform: scale(1.15);
      transition: ${({ theme }) => theme.durations.transition};
    }
  }
  ${ButtonPrev} {
    left: -10px;
    display: ${props => (props.isFirstSlide ? "none" : "block")};

    &::before {
      left: 7px;
      transform: rotate(90deg);
      ${({ theme }) => theme.durations.transition};
    }
  }

  ${ButtonNext} {
    right: -10px;
    display: ${props => (props.isLastSlide ? "none" : "block")};
    ${({ theme }) => theme.durations.transition};

    &::before {
      right: 7px;
      transform: rotate(-90deg);
    }
  }
`
export const Title = styled.h3`
  font-size: 24px;
  line-height: 36px;
  color: white;
  margin-bottom: 0;
  margin-top: 10px;

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    font-size: 36px;
    line-height: 56px;
  }
`
export const TextWrap = styled.div`
  @media (min-width: ${({ theme }) => theme.media.desktop_min}) {
    max-width: 85%;
  }
  @media (min-width: ${({ theme }) => theme.media.wideScreen_xl}) {
    max-width: 96%;
  }
  p {
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 18px;
    color: #fff;

    @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
      font-size: 20px;
      line-height: 24px;
    }
  }
`

export const StyledSwiper = styled(Swiper)`
  min-height: 466px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  @media (min-width: 768px) {
    max-width: 730px;
    height: 432px;
  }
  @media (min-width: 1180px) {
    max-width: 100%;
  }
  @media (max-width: 1024px) {
    min-height: initial;
    height: auto;
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: 40px;
    height: 40px;
    background: ${({ theme }) => theme.colors.primary_color};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      font-size: 20px;
      color: #fff;
      font-weight: 700;
    }

    &:hover,
    &:active,
    &:focus {
      background: ${({ theme }) => theme.colors.secondary_color};
    }
  }
  .swiper-button-prev {
    transform: translateY(-20px) translateX(-10px);
  }

  .swiper-button-next {
    transform: translateY(-20px) translateX(10px);
  }

  .swiper-button-disabled {
    display: none;
  }
`

export const Box = styled.div`
  box-shadow: ${({ theme }) => theme.box.boxShadow};
  text-align: center;
  overflow: hidden;
  opacity: ${props => (props.isPast ? "0.6" : "1.0")};
  position: relative;
  height: 446px;
  max-width: 360px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary_color};

  .header-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  picture {
    display: block;
    img {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 101%;
      height: 100%;
      object-fit: cover;
    }
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0.5rem 0.5rem 60px;
  text-align: left;
  background: linear-gradient(
    180.03deg,
    ${props => props.color} 23.88%,
    rgba(68, 170, 153, 0) 121.4%
  );

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    width: 60%;
  }
`

export const CardTitle = styled.div`
  font-size: 20px;
  line-height: 1.25;
  padding-left: 12px;
  font-weight: 600;
  color: white;
  position: relative;

  &::before {
    content: "";
    width: 3px;
    height: 100%;
    background: white;
    position: absolute;
    left: 0;
  }
`

export const Button = styled(Link)`
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.primary_color};
  color: white;
  width: 100%;
  font-size: 24px;
  line-height: 1.08;
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.3s;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary_color};
  }
`

export const Description = styled.div`
  margin-top: 0.5rem;
  font-size: 15px;
  line-height: 1.33;
  color: white;
`

export const Author = styled(Description)`
  font-weight: 600;
  margin-top: 0.5rem;
`

export const HeaderText = styled.div``
export const FooterText = styled.div``
