import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Icon } from "../Icon"
const CardLogo = ({name, color}) => {
	return (
		<LogoWrapper>
			<h3>
				{name}
				<Icon type="PATH" fill={color}/>
			</h3>
		</LogoWrapper>
	)
}

CardLogo.propTypes = {
	name: PropTypes.string.isRequired,
	color: PropTypes.string.isRequired,
}

export default CardLogo

const LogoWrapper = styled.div`
  position: absolute;
  right: 3px;
  top: 20px;
  z-index: 10;
  text-align: left;

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    right: 3px;
  }

  h3 {
    position: relative;
    color: white;
    margin-left: auto;
    max-width: 74px;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;

    @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
      font-size: 14px;
      line-height: 18px;
      max-width: 108px;
    }

    svg {
      position: absolute;
      left: -18px;
      top: -9px;
      height: 50px;
      width: 50px;

      @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
        left: -25px;
        top: -15px;
        height: 70px;
        width: 70px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    width: 130px;
    height: 93px;
  }
`
