import React from "react"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { separateEvents } from "../utils/separateEvents"

import { MainBackground } from "../components/MainBackground"
import {
  BlogsList,
  CategoryHeader,
  EventList,
  QuestionsList,
  ResearchList,
} from "../components/Category"
import { Container } from "../components/Container"
import IChallengesList from "../components/Category/IChallengesList"
import PodcastList from "../components/Category/PodcastList";
import CategoryInfo from "../components/Category/CategoryInfo";

const FullCategoryTemplate = ({ data, location }) => {
  const {
    allPrismicResearch: { edges: allResearches },
    allPrismicEvent: { edges: allEvents },
    allPrismicBlog: { edges: allBlogs },
    allPrismicQuestion: { edges: allFaqs },
    allPrismicCategory: { edges: category },
    allPrismicInnovationChallenge: { edges: allChallenges },
    allPrismicPodcast: { edges: allPodcast },
    allPrismicSeo: { edges: allSeo },
  } = data
  console.log(allSeo);
  const categoryName = category[0].node?.data?.name
  const aboutCategory = category[0].node?.data?.about_category?.text
  return (
    <Layout location={location}>
      <Seo pathname={location.pathname} title="Category" />
      <MainBackground type="blue" container>
        <Container>
          <CategoryHeader
            categoryName={categoryName}
            aboutCategory={aboutCategory}
          />
          {allResearches.length > 0 && <ResearchList data={allResearches} />}
          {allEvents.length > 0 && (
            <EventList data={separateEvents(allEvents) || allEvents} />
          )}
          {allChallenges?.length > 0 && (
              <IChallengesList data={allChallenges} />
          )}
          {allBlogs.length > 0 && (
            <BlogsList data={allBlogs} categoryName={categoryName} />
          )}
          {allPodcast.length > 0 && (
            <PodcastList data={allPodcast} location={location} categoryName={categoryName} />
          )}
          {allSeo.length > 0 && <CategoryInfo allSeo={allSeo}/>}
          {allFaqs.length > 0 && (
            <QuestionsList data={allFaqs} categoryName={categoryName} />
          )}
        </Container>
      </MainBackground>
    </Layout>
  )
}

export default FullCategoryTemplate

export const query = graphql`
  query ($eq: String) {
  allPrismicResearch(
    filter: {data: {category: {uid: {eq: $eq}}}}
    sort: {order: DESC, fields: first_publication_date}
  ) {
    edges {
      node {
        data {
          title {
            text
          }
          subtitle
          category {
            document {
              ... on PrismicCategory {
                uid
                id
                data {
                  name
                  color
                }
              }
              ... on PrismicResearch {
                id
                data {
                  subtitle
                }
              }
              ... on PrismicEvent {
                id
                data {
                  organiser
                }
              }
            }
          }
          authors {
            author {
              document {
                ... on PrismicPerson {
                  id
                  data {
                    name
                  }
                }
                ... on PrismicBlog {
                  id
                  data {
                    excerpt
                    meta_description
                    meta_title
                  }
                }
              }
            }
          }
        }
        uid
        id
      }
    }
  }
  allPrismicSeo(
   filter: {data: {category: {uid: {eq: $eq}}}}
   ) {
    edges {
      node {
        id
        data {
          category {
            uid
          }
          seo_content {
            html
          }
          seo_title {
            text
          }
        }
      }
    }
  }
  allPrismicEvent(
    filter: {data: {category: {uid: {eq: $eq}}}}
    sort: {order: DESC, fields: data___start_date}
  ) {
    edges {
      node {
        data {
          category {
            uid
            document {
              ... on PrismicCategory {
                id
                data {
                  color
                  name
                }
              }
            }
          }
          title {
            text
          }
          custom_date
          description
          organiser
          start_date
          city
          register_label
          register_url {
            url
            uid
            type
            target
          }
        }
        uid
        id
      }
    }
  }
  allPrismicBlog(
    filter: {data: {category: {uid: {eq: $eq}}}}
    sort: {order: DESC, fields: first_publication_date}
  ) {
    edges {
      node {
        data {
          author {
            document {
              ... on PrismicPerson {
                uid
                type
                data {
                  name
                  title
                  photo {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          title {
            text
          }
          featured_image {
            url
            gatsbyImageData(layout: FULL_WIDTH, width: 1200)
          }
          category {
            uid
            document {
              ... on PrismicCategory {
                id
                data {
                  name
                  color
                }
              }
            }
          }
        }
        id
        uid
        first_publication_date
      }
    }
  }
  allPrismicQuestion(filter: {data: {category: {uid: {eq: $eq}}}}) {
    edges {
      node {
        uid
        data {
          question {
            text
            html
          }
          answer {
            html
          }
          category {
            uid
            document {
              ... on PrismicCategory {
                id
                data {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
  allPrismicCategory(filter: {uid: {eq: $eq}}) {
    edges {
      node {
        data {
          name
          about_category {
            html
            text
            richText
            raw
          }
        }
      }
    }
  }
  allPrismicInnovationChallenge(
    filter: {data: {category: {uid: {eq: $eq}}}}
    sort: {order: DESC, fields: first_publication_date}
  ) {
    edges {
      node {
        uid
        data {
          logo {
            gatsbyImageData
          }
          title {
            text
          }
        }
      }
    }
  }
  allPrismicPodcast {
    edges {
      node {
        uid
        data {
          title {
            text
            html
          }
          social_card {
            gatsbyImageData
            alt
          }
          series_image {
            url
            gatsbyImageData
            alt
          }
          meta_title
          meta_description
          episodes {
            apple_podcast_link {
              url
            }
            episode_link {
              url
            }
            episode_podcast {
              url
            }
            episode_text {
              html
              text
            }
            episode_title {
              html
              text
            }
            spotify_link {
              url
            }
          }
          content {
            text
            html
          }
        }
      }
    }
  }
}
`
