import React from "react"
import styled from "styled-components"
import MainTitle from "../CommonComponents/MainTitle";

const CategoryHeader = ({ categoryName, aboutCategory }) => {
  return (
    <Wrapper>
      <MainTitle variant="h1">{categoryName}</MainTitle>
      <SubText dangerouslySetInnerHTML={{ __html: aboutCategory }}></SubText>
    </Wrapper>
  )
}

export default CategoryHeader

const Wrapper = styled.div`
  @media (min-width: ${({ theme }) =>
      theme.media.tablet_max}) and (max-width: 1340px) {
    max-width: 94%;
  }
`

const SubText = styled.div`
  margin-bottom: 15px;
  p {
    ${({ theme }) => theme.paragraphs.p};
    font-size: 16px;
    margin-bottom: 10px;
    color: #fff;

    @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
      font-size: 22px;
    }
  }
`
