import React from 'react';
import styled from "styled-components";
import {Container} from "../Container";

const CategoryInfo = ({allSeo}) => {
  return (
    <CategoryInfoWrapper>
      <Container>
        {allSeo.map(item => (
          <SeoContent dangerouslySetInnerHTML={{ __html: item.node.data.seo_content.html }}/>
        ))}
      </Container>
    </CategoryInfoWrapper>
  );
};

const CategoryInfoWrapper = styled.div`
  background: rgb(255, 255, 255);
  padding: 18px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    width: calc(100vw - 18px);
    background: white;
    transform: translateX(-50%);
    height: 100%;
  }
  h2, h3, h4, h5, h6, p {
    margin-bottom: 10px;
  }
`
const SeoContent = styled.div`
  ul {
    list-style: inside;
    margin-bottom: 15px;
  }
`

export default CategoryInfo;