import React, { useRef, useState } from "react"
import moment from "moment/moment"
import { truncateString } from "../../utils"
import { reduceStringByWordsSeparatedByComma } from "../../utils/reduceStringByWordsSeparatedByComma"

import { categoryCovers } from "../../assets/categoryCovers/categoryCovers"

import styled from "styled-components"
import {
  Author,
  Box,
  Button,
  ButtonNext,
  ButtonPrev,
  CardTitle,
  ContentWrapper,
  Description,
  FooterText,
  HeaderText,
  Section,
  SliderWrapper,
  StyledSwiper,
  TextWrap,
  Title,
} from "./ResearchList"

import { SwiperSlide } from "swiper/react"
import CardLogo from "../CardLogo"

const EventList = ({ data }) => {
  const [isFirstSlide, setIsFirstSlide] = useState()
  const [isLastSlide, setIsLastSlide] = useState()
  const swiperRef = useRef(null)

  const handlePrevButtonClick = () => {
    swiperRef.current.slidePrev()
  }

  const handleNextButtonClick = () => {
    swiperRef.current.slideNext()
  }

  return (
    <Section>
      <TextWrap>
        <Title>Our Meetings</Title>
        <p>
          ECR Retail Loss Group regular working group meetings provide an
          opportunity to network with industry peers, hear updates on the latest
          research and sector initiatives, and development new skills and
          insights. All retailers, CPGs and academics can participate at no
          cost.
        </p>
      </TextWrap>

      <SliderWrapper isFirstSlide={isFirstSlide} isLastSlide={isLastSlide}>
        <StyledSwiper
          onSwiper={swiper => {
            swiperRef.current = swiper
            setIsFirstSlide(swiper.isBeginning)
            setIsLastSlide(swiper.isEnd)
          }}
          onResize={swiper => {
            setIsFirstSlide(swiper.isBeginning)
            setIsLastSlide(swiper.isEnd)
          }}
          onActiveIndexChange={swiper => {
            setIsFirstSlide(swiper.isBeginning)
            setIsLastSlide(swiper.isEnd)
          }}
          observer={true}
          observeParents={true}
          spaceBetween={15}
          slidesPerView={1}
          enabled={true}
          slidesPerGroup={1}
          breakpoints={{
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
              enabled: data.length > 2,
              slidesPerGroup: 2,
            },
            1180: {
              slidesPerView: 3,
              enabled: data.length > 3,
              slidesPerGroup: 3,
            },
          }}
        >
          {data?.map((el, i) => {
            return (
              <SwiperSlide key={i}>
                <EventItem event={el.node} />
              </SwiperSlide>
            )
          })}
        </StyledSwiper>
        <ButtonPrev onClick={handlePrevButtonClick}></ButtonPrev>
        <ButtonNext onClick={handleNextButtonClick}></ButtonNext>
      </SliderWrapper>
    </Section>
  )
}

export default EventList

export const EventItem = ({ event, basePath = "/event", location }) => {
  const {
    data: {
      category,
      custom_date: customDate,
      description,
      organiser,
      start_date: startDate,
      end_date: endDate,
      title,
    },
    uid,
  } = event
  const isPast = moment() > moment(startDate)
  const eventURL = `${basePath}/${uid}`

  const url = `${eventURL}${
    location?.pathname?.includes("event") ? `?from=${location.pathname}` : ""
  }`

  return (
    <Item isPast={isPast}>
      <CardLogo
        name={category?.document?.data.name}
        color={category?.document?.data.color}
      />
      <Box>
        <picture>
          <img src={categoryCovers[category?.uid]?.background} alt="" />
        </picture>
        <ContentWrapper color={category?.document?.data?.color}>
          <HeaderText>
            <CardTitle>{truncateString(title.text, 70)}</CardTitle>
          </HeaderText>
          <FooterText>
            <Date>
              {customDate ? (
                customDate
              ) : (
                <>
                  {moment.utc(startDate).format("D MMM YYYY")}
                  {endDate && ` - ${moment.utc(endDate).format("D MMM YYYY")}`}
                </>
              )}
            </Date>
            <UpComing>{isPast ? "Past" : "UpComing"}</UpComing>
            <Description>
              {truncateString(description ? description : "", 120)}
            </Description>
            <Author>{reduceStringByWordsSeparatedByComma(organiser)}</Author>
            <Button to={url}>Learn more</Button>
          </FooterText>
        </ContentWrapper>
      </Box>
    </Item>
  )
}

const Item = styled.div`
  opacity: ${props => (props.isPast ? "0.6" : "1.0")};
  position: relative;
  max-width: 355px;
  width: 100%;
  height: 446px;
`

const UpComing = styled.div`
  color: white;
  margin-top: 0.1rem;
  font-size: 15px;
  line-height: 1.67;
  text-transform: uppercase;
`

const Date = styled.div`
  color: white;
  text-align: left;
  margin-top: 0.1rem;
  font-size: 15px;
  line-height: 1.67;
`
