import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const InnovationChallengeItem = ({ challenge }) => {
  return (
    <Item>
      <Link to={`/innovation/${challenge.uid}`}>
        <Image>
          <GatsbyImage
            image={getImage(challenge.data.logo.gatsbyImageData)}
            alt="logo"
            objectFit="contain"
          />
        </Image>
      </Link>
      <TextWrap>{challenge.data.title.text}</TextWrap>
    </Item>
  )
}

export default InnovationChallengeItem

const Item = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 12px;
  background-color: #fff;
  box-shadow: ${({ theme }) => theme.box.boxShadow};
  &:hover {
    img {
      transform: scale(1.04);
    }
  }

  img {
    display: block;
    transition: 0.7s ease-in-out !important;
  }

  h4 {
    font-size: 24px;
    line-height: calc(32 / 24);
    position: relative;
    margin: 0 0 12px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-right: 20px;
  }

  a {
    &:hover {
      h4 {
        &:after {
          transform: rotate(5deg) translateX(-2px);
          opacity: 0.6;
        }
      }
    }
  }

  & > a {
    &:nth-last-of-type(1) {
      flex-grow: 1;
    }
  }
`

const Image = styled.div`
  display: flex;
  height: 240px;

  .gatsby-image-wrapper {
    width: 100%;
  }
`

const TextWrap = styled.span`
  display: block;
  margin-top: auto;
  font-size: 24px;
  font-weight: 700;
`
